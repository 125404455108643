<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="false" :title="headerTitle"/>

      <div class="row">
        <div class="col-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >
            Cash & Bank Account
          </label>
          <v-select
              placeholder="Select Cash & Bank Account"
              class="w-100"
              v-model="reconciliationData.account_head_id"
              :options="cashAndBack"
              label="name"
              :reduce="name => name.id"
              @option:selected="onChangeAccountHead"
              :disabled="!isReconciliationCreate"
          />
        </div>
        
        <div class="col-3">
          <label
              class="col-form-label"
          >
            Reconciliation Ref
          </label>
          <input
              :value="reconciliationData.reconciliation_ref"
              type="text"
              class="form-control"
              placeholder="Reconciliation Ref"
              readonly
          >
        </div>

        <div class="col-3">
          <label
              for="colFormLabel"
              class="col-form-label col-xl-3"
          >
            From
          </label>
          <input
              v-model="reconciliationData.start"
              type="date"
              class="form-control date-mask"
              placeholder="From"
              readonly
          >
        </div>

        <div class="col-3">
          <label
              for="colFormLabel"
              class="col-form-label col-xl-3"
          >
            To
          </label>
          <input
              v-model="reconciliationData.end"
              type="date"
              class="form-control date-mask"
              placeholder="From"
              @change="getBankBookBalance"
              :disabled="!isReconciliationCreate"
          >
        </div>

        <div class="col-3">
          <label
              class="col-form-label"
          >
            Bank Book Balance
          </label>
          <input
              v-model="reconciliationData.bank_book_balance"
              type="text"
              class="form-control"
              placeholder="Bank Book Balance"
              readonly
          >
        </div>

        <div class="col-3">
          <label
              class="col-form-label"
          >
            Bank Statement Balance
          </label>
          <input
              v-model="reconciliationData.bank_statement_balance"
              type="number"
              class="form-control"
              placeholder="Bank Statement Balance"
          >
        </div>

        <div class="col-3">
          <label
              class="col-form-label"
          >
            Difference
          </label>
          <input
              :value="reconciliationData.difference"
              type="text"
              class="form-control"
              placeholder="Reconciliation Ref"
              readonly
          >
        </div>

        <div class="col-2 d-flex align-items-end mt-2">
          <button
              :disabled="!isReconciliationCreate"
              style="min-width: 64px;"
              @click="getReconciliationData"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
          >
            Go
          </button>
        </div>
      </div>
    </div>

    <div class=" p-2">
      <div class="table-responsive border-b border-l border-r">
        <table class="table">
          <thead>
          <tr>
            <th>Voucher Date</th>
            <th>Voucher no</th>
            <th>Payment Ref.</th>
            <th>Payment Ref date</th>
            <th>Party</th>
            <th>Particulars</th>
            <th>Line description</th>
            <th>Receipts</th>
            <th>Payments</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(item, i) in previousLedgers" :key="i">
            <td>
              <span class="fw-bold">{{ item.date }}</span>
            </td>
            <td> {{ item.voucher_no }} </td>
            <td> {{ item.payment_ref }} </td>
            <td> {{ item.payment_ref_date }} </td>
            <td> {{ item.party }} </td>
            <td>
              <span v-if="(item.receipt == null || item.receipt === 0)">
                    {{ item.receipt_account_head }}
                </span>
              <span v-if="(item.payment == null || item.payment === 0)">
                    {{ item.payment_account_head }}
              </span>
            </td>
            <td> {{ item.line_description }} </td>
            <td class="text-end"> {{ commaFormat(item.receipt) }} </td>
            <td class="text-end"> {{ commaFormat(item.payment) }} </td>
            <td>
              <input type="checkbox" class="largerCheckbox"
                     :checked="selected.includes(item.id)"
                     @input="check(item.id)"
              >
            </td>
          </tr>
          </tbody>
        </table>
         <p v-if="!previousLedgers.length" class="mt-2 text-center">No data</p>
      </div>
    </div>

    <div class=" p-2" style="min-height: 200px">
      <div class="table-responsive border-b border-l border-r">
        <table class="table">
          <thead>
          <tr>
            <th>Voucher Date</th>
            <th>Voucher no</th>
            <th>Payment Ref.</th>
            <th>Payment Ref date</th>
            <th>Party</th>
            <th>Particulars</th>
            <th>Line description</th>
            <th>Receipts</th>
            <th>Payments</th>
            <th>Balance</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td> Opening Balance </td>
            <td class="text-end">  </td>
            <td class="text-end">  </td>
            <td class="text-end"> {{ openingBalance ? commaFormat(openingBalance) : 0 }} </td>
            <td>  </td>
          </tr>
          <tr v-for="(item, i) in currentLedgers" :key="i">
            <td>
              <span class="fw-bold">{{ item.date }}</span>
            </td>
            <td> {{ item.voucher_no }} </td>
            <td> {{ item.payment_ref }} </td>
            <td> {{ item.payment_ref_date }} </td>
            <td> {{ item.party }} </td>
            <td>
              <span v-if="(item.receipt == null || item.receipt === 0)">
                    {{ item.receipt_account_head }}
                </span>
              <span v-if="(item.payment == null || item.payment === 0)">
                    {{ item.payment_account_head }}
              </span>
            </td>
            <td> {{ item.line_description }} </td>
            <td class="text-end"> {{ commaFormat(item.receipt) }} </td>
            <td class="text-end"> {{ commaFormat(item.payment) }} </td>
            <td class="text-end"> {{ commaFormat(item.balance) }} </td>
            <td>
              <input type="checkbox" class="largerCheckbox"
                     :checked="selected.includes(item.id)"
                     @input="check(item.id)"
              >
            </td>
          </tr>
          <tr>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td>  </td>
            <td> Closing Balance </td>
            <td class="text-end"> {{ totalReceipt ? commaFormat(totalReceipt) : 0 }}</td>
            <td class="text-end"> {{ totalPayment ? commaFormat(totalPayment) : 0 }}  </td>
            <td class="text-end"> {{ closingBalance ? commaFormat(closingBalance) : 0 }}</td>
            <td>  </td>
          </tr>

          </tbody>
        </table>
        <!-- <p v-if="!ledgers.length" class="mt-5 text-center">No data</p> -->
      </div>
    </div>

    <div class="p-2">
      <div class="row">
        <div class="col-md-12 text-right mb-2">
          <button :disabled="!selected.length" @click="saveReconciliation"
                  class="btn btn-primary btn-block waves-effect waves-float waves-light">
            {{isReconciliationCreate == false ? 'Update' : 'Save' }}
          </button>
        </div>
      </div>
    </div>

    <GlobalLoader />
  </div>
</template>

<script setup>
import {
  computed,
  inject,
  onMounted,
  ref,
  watch
} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import {useRoute, useRouter} from "vue-router";
import handleReport from "@/services/modules/accountingReport";
import handlePayment from "@/services/modules/payment";
import figureFormatter from "@/services/utils/figureFormatter";

const {
  fetchSpecificReconciliationData,
  fetchReconciliationNumber,
  fetchLastReconciliationDate,
  fetchReconciliationDataByRef,
  fetchBankBookBalance,
  createReconciliation,
  updateReconciliation
} = handleReport();
const {fetchCashAndBank} = handlePayment();
const {commaFormat} = figureFormatter ()
const router = useRouter()
const route = useRoute()
const showError = inject("showError");
const showSuccess = inject("showSuccess");

let loading = ref(false);
let companyId = computed(() => route.params.companyId);
let isReconciliationCreate = ref(true);
let headerTitle = ref("Create Bank Reconciliation");
let cashAndBack = ref([]);
let previousLedgers = ref([]);
let currentLedgers = ref([]);
let totalPayment = ref(0);
let totalReceipt = ref(0);
let openingBalance = ref(0);
let closingBalance = ref(0);
let selected = ref([]);
let reconciliationData = ref({
  account_head_id : null,
  start : "",
  end : new Date().toISOString().split('T')[0],
  reconciliation_ref : "",
  bank_book_balance : "",
  bank_statement_balance : "",
  difference : "",
});

const validationCheck = computed(() => {
  if (reconciliationData.value.account_head_id === null || reconciliationData.value.account_head_id === undefined) {
    showError("Please select cash & bank account head");
    resetBalances();
    return false;
  }

  let from = new Date(reconciliationData.value.start);
  let to = new Date(reconciliationData.value.end);
  if (to < from){
    showError("To date must be greater than the from date");
    return false;
  }

  if (reconciliationData.value.bank_statement_balance == null || reconciliationData.value.bank_statement_balance === "" || reconciliationData.value.bank_statement_balance == 0) {
    showError("Please input bank statement balance");
    return false;
  }

  return true;
})
const watchBankStatementBalance = computed(() => {
  return reconciliationData.value.bank_statement_balance
})

watch(watchBankStatementBalance, (newValue, oldValue) => {
  if (newValue !== oldValue){
    return reconciliationData.value.difference = commaFormat((reconciliationData.value.bank_book_balance - reconciliationData.value.bank_statement_balance));
  }
})

async function getReconciliationData() {
  if (! validationCheck.value){
    return;
  }

  let query = getQuery();
  try {
    const res = await fetchSpecificReconciliationData({
      headId: reconciliationData.value.account_head_id,
      query: query,
    });
    if (res.status) {
      previousLedgers.value = res.data.previousLedgers;
      currentLedgers.value = res.data.currentLedgers;
      openingBalance.value = res.data.opening_balance;
      closingBalance.value = res.data.closing_balance;
      totalReceipt.value = res.data.total_receipt;
      totalPayment.value = res.data.total_payment;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

async function getReconciliationNumber() {
  try {
    const res = await fetchReconciliationNumber(getQuery());
    if (res.status) {
      reconciliationData.value.reconciliation_ref = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

async function getLastReconciliationDate() {
  if (reconciliationData.value.account_head_id === null || reconciliationData.value.account_head_id === undefined) {
    showError("Please select cash & bank account head");
    return;
  }
  let query = getQuery();
  query += "&account_head_id=" + reconciliationData.value.account_head_id;

  try {
    const res = fetchLastReconciliationDate(query);
    res.then(data => {
      if (data.data != ""){
        reconciliationData.value.start = data.data;
      }
    })
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

async function getBankBookBalance() {
  if (reconciliationData.value.account_head_id === null || reconciliationData.value.account_head_id === undefined) {
    showError("Please select cash & bank account head");
    return;
  }

  let query = getQuery();
  query += "&account_head_id=" + reconciliationData.value.account_head_id;
  query += "&end_date=" + reconciliationData.value.end;

  try {
    let res = await fetchBankBookBalance(query);
    if(!res.status) {
      showError(res.message)
    }
    if (res.status) {
      reconciliationData.value.bank_book_balance = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

async function getCashAndBank() {
  try {
    let res = await fetchCashAndBank(companyId.value)
    if(!res.status) {
      showError(res.message)
    }
    if(res.status) {
      cashAndBack.value = res.data
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

async function saveReconciliation(){
  if (! validationCheck.value){
    return;
  }

  let data = {
    company_id : companyId.value,
    selected_ids : selected.value,
    account_head_id : reconciliationData.value.account_head_id,
    reconciliation_ref : reconciliationData.value.reconciliation_ref,
    bank_book_balance : reconciliationData.value.bank_book_balance,
    bank_statement_balance : reconciliationData.value.bank_statement_balance,
    start_date : reconciliationData.value.start,
    end_date : reconciliationData.value.end,
  };

  if(!isReconciliationCreate.value){
    data._method = "put";
  }

  try {
    loading.value = true;

    let res = isReconciliationCreate.value
              ? await createReconciliation(data)
              : await updateReconciliation(reconciliationData.value.reconciliation_ref, data);

    if(!res.status) {
      showError(res.message)
    }
    if(res.status) {
      showSuccess(res.message)
      resetAllData();
      goToList();
    }
  } catch (err) {
    if(!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if(err.response) {
      showError(err.response.data.message)
    }
  } finally {
    loading.value  = false;
  }
}

async function onChangeAccountHead(){
  await resetBalances();
  await getLastReconciliationDate();
  await getBankBookBalance();
}

async function getReconciliationDataByRef() {
  let query = getQuery();
  try {
    const res = await fetchReconciliationDataByRef(reconciliationData.value.reconciliation_ref, query);
    if (res.status) {
      reconciliationData.value = res.data.reconciliation_data;
      reconciliationData.value.difference = (reconciliationData.value.bank_book_balance - reconciliationData.value.bank_statement_balance);
      previousLedgers.value = res.data.previous_ledgers;
      currentLedgers.value = res.data.current_ledgers;
      openingBalance.value = res.data.opening_balance;
      closingBalance.value = res.data.closing_balance;
      totalReceipt.value = res.data.total_receipt;
      totalPayment.value = res.data.total_payment;

      /**
       * Updated Bank Book Balance
       */
      await getBankBookBalance();

      if (previousLedgers.value.length){
        previousLedgers.value.forEach((item)=>{
          if (item.reconciliation_ref === reconciliationData.value.reconciliation_ref){
            selected.value.push(item.id);
          }
        });
      }

      if (currentLedgers.value.length){
        currentLedgers.value.forEach((item)=>{
          if (item.reconciliation_ref === reconciliationData.value.reconciliation_ref){
            selected.value.push(item.id);
          }
        });
      }
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function getQuery() {
  let query = '?company_id=' + companyId.value;
  if (reconciliationData.value.start) query+= '&start=' + reconciliationData.value.start;
  if (reconciliationData.value.end) query+= '&end=' + reconciliationData.value.end;
  return query;
}

function check(id) {
  if (selected.value.includes(id)) {
    selected.value.splice(selected.value.indexOf(id), 1);
  } else {
    selected.value.push(id);
  }
}

function resetAllData(){
  reconciliationData.value.account_head_id = null;
  reconciliationData.value.start = "";
  reconciliationData.value.end = new Date().toISOString().split('T')[0];
  reconciliationData.value.reconciliation_ref = "";
  reconciliationData.value.bank_book_balance = "";
  reconciliationData.value.bank_statement_balance = "";
  reconciliationData.value.difference = "";

  previousLedgers.value = [];
  currentLedgers.value = [];
  totalReceipt.value = 0;
  totalPayment.value = 0;
  openingBalance.value = 0;
  closingBalance.value = 0;
}

function resetBalances(){
  reconciliationData.value.bank_book_balance = "";
  reconciliationData.value.bank_statement_balance = "";
  reconciliationData.value.difference = "";
}

function  goToList() {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let query = route.query;
  delete query.reconciliationRef;

  router.push({
    name: 'bank-reconciliation',
    params: param,
    query: query
  })
}

onMounted(async () => {
  await getCashAndBank();

  if (route.query.reconciliationRef && route.query.reconciliationRef !== "" && typeof route.query.reconciliationRef !== undefined){
    isReconciliationCreate.value = false;
    headerTitle.value = "Update Bank Reconciliation";
    reconciliationData.value.reconciliation_ref = route.query.reconciliationRef;
    await getReconciliationDataByRef();
  }

  if (isReconciliationCreate.value){
    await getReconciliationNumber();
  }
})
</script>

<style scoped>
input.largerCheckbox {
  width: 20px;
  height: 20px;
}
</style>
